import { useState } from "react";
import { Box, Button, Stack, TextField, Tooltip } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../../assets/css/login.css";

import { selectData } from "../../../store/reducers/settings";
import { useCart } from "../../../context/CartContext";
// import firebaseconfig, { auth } from "../../../utils/firebase";
import config from "../../../utils/config";
import * as api from "../../../utils/api";
import { useAuth } from "../../../context/AuthContext";
import { useProfile } from "../../../context/ProfileContext";
import { validateEmailId, validatePhoneNumber } from "../input-validations/input-validations";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import firebaseServices from "../../../utils/firebase";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";

const demoMOde = process.env.REACT_APP_DEMO_MODE;
const SignIn = ({ setProfile, setNewUserScreen, setEmailInputType, handleLoginClose, auth }) => {
    const [confirmResult, setConfirmResult] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [phoneWithoutCountry, setPhoneWithoutCountry] = useState("");
    const [userId, setUserIdSignIn] = useState("");
    const [isSend, setIsSend] = useState(false);
    const [load, setLoad] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [CartData, setCartData] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setUserInfo } = useProfile();
    const { setUserDetails } = useAuth();
    const { get_cart } = useCart();
    const authentication_mode = useSelector(selectData)?.authentication_mode;
    // let { auth } = firebaseServices;

    const navigateToCart = () => {
        setTimeout(() => {
            navigate("/cart");
            window.location.reload();
            // navigate("/", { replace: true });
        }, 100);
    };

    const updatUserId = (userId) => {
        setUserIdSignIn(userId);
    };

    const updateNewUserScreen = (isNewScreen) => {
        setNewUserScreen(isNewScreen);
        setEmailInputType(inputType);
        setProfile({
            username: "",
            mobile: phoneNumber,
            email: email,
            profile: "",
        });
    };

    // call send otp to email 
    const sendOtpToEmail = () => {
        api
            .sendOtpToEmail(email)
            .then((response) => {
                setLoad(false);
                if (response.error) {
                    if (response.message === "Invalid Email") {
                        toast.error("Invalid Email. Please enter valid email.");
                    } else {
                        toast.error(response.message);
                        updateNewUserScreen(true);
                    }
                } else {
                    setIsSend(true);
                    // setConfirmResult(response);
                    toast.success("OTP has been sent");
                }
            })
            .catch((error) => {
                toast.error(error.message);
                setLoad(false);
            });
    };

    // proceed to logged in after verify otp
    const proceedToLoggedIn = (res) => {
        let userData = res.data;
        userData = { ...userData, token: res.token };
        setUserDetails(userData);
        updatUserId(res.uid);
        setUserInfo(userData);
        // console.log(res.data.id);

        const cart_data = localStorage.getItem("cart");
        const cart = JSON.parse(cart_data);
        // console.log(cart);
        // console.log(res.data.id);
        // console.log("----- Existing user authentication ----");
        if (cart && cart.length > 0) {
            api
                .add_to_cart_data(
                    res.data.id,
                    false,
                    cart
                )
                .then((cartResp) => {
                    if (!cartResp.error) {
                        console.log(cartResp);
                        setCartData(cartResp.data);
                        localStorage.removeItem("cart");
                        get_cart();
                    }
                });
        }

        toast.success("Logged in Successfully");
        handleLoginClose();
        setTimeout(function () {
            navigateToCart();
        }, 1000);
    };

    //sign with number
    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(true);

        if (inputType === "email") {

            if (validateEmailId(email)) {
                sendOtpToEmail();
            } else {
                setLoad(false);
                toast.error("Please Enter valid Email ID");
            }

        } else {
            let appVerifier;
            if (window.recaptchaVerifier) {
                appVerifier = window.recaptchaVerifier;
                if (!appVerifier) {
                    toast.error("reCAPTCHA is not initialized. Please try again.");
                    setLoad(false);
                    return;
                }
            }
            // Use the reCAPTCHA verifier
            if (authentication_mode === 1) {

                let phone_number = "+" + phoneNumber;
                if (validatePhoneNumber(phone_number)) {
                    api
                        .verify_user(phoneWithoutCountry)
                        .then((response) => {
                            setIsSend(true);
                            setLoad(false);
                            setConfirmResult(response);
                            toast.success("OTP has been sent");
                        })
                        .catch((error) => {
                            appVerifier.render().then(function (widgetId) {
                                window.grecaptcha.reset(widgetId);
                            });
                            toast.error(error.message);
                            setLoad(false);
                        });
                } else {
                    setLoad(false);
                    toast.error("Please Enter correct Mobile Number with Country Code");
                }
            } else {
                let phone_number = "+" + phoneNumber;
                if (validatePhoneNumber(phone_number)) {
                    try {
                        // const autht = getAuth();
                        const response = await signInWithPhoneNumber(auth, phone_number, appVerifier);
                        console.log(response);
                        // .then((response) => {
                        setIsSend(true);
                        setLoad(false);
                        setConfirmResult(response);
                    } catch (error) {
                        console.log(error);
                        // .catch((error) => {
                        appVerifier.render().then(function (widgetId) {
                            window.grecaptcha.reset(widgetId);
                        });
                        toast.error(error.message);
                        setLoad(false);
                    }
                    // })
                    // });
                } else {
                    setLoad(false);
                    toast.error("Please Enter correct Mobile Number with Country Code");
                }
            }
        }
    };

    //verify code
    const handleVerifyCode = (e) => {
        e.preventDefault();
        setLoad(true);
        if (inputType === "email") {
            api
                .verify_otp_email({ email, verificationCode })
                .then((response) => {
                    setLoad(false);
                    setProfile(response?.user);
                    if (!response.error) {
                        console.log(response);
                        if (response.data.length === 0) { // new user signup flow
                            toast.success(response.message);
                            updateNewUserScreen(true);
                        } else {
                            response['uid'] = response.data.id;
                            proceedToLoggedIn(response);
                        }

                    } else {
                        console.log(response.message);
                        toast.error("Entered otp is wrong. Please enter correct otp");
                    }
                });
        } else {
            if (authentication_mode === 1) {
                api
                    .verify_otp({ phoneWithoutCountry, verificationCode })
                    .then((response) => {
                        setLoad(false);
                        setProfile(response?.user);
                        if (!response.error) {
                            api.userAuth(parseInt(phoneWithoutCountry), "").then((res) => {
                                if (res.data.length === 0) {
                                    toast.error(res.message);
                                    updateNewUserScreen(true);
                                } else {
                                    proceedToLoggedIn(res);
                                }
                            });
                        } else {
                            console.log(response.message);
                            toast.error("Entered otp is wrong. Please enter correct otp");
                        }

                    });
            } else {
                confirmResult
                    .confirm(verificationCode)
                    .then((response) => {
                        setLoad(false);
                        setProfile(response.user);
                        if (response._tokenResponse.isNewUser) {
                            updateNewUserScreen(true);
                        } else {
                            api
                                .userAuth(parseInt(phoneWithoutCountry), "")
                                .then((res) => {
                                    if (res.error) {
                                        toast.error(res.message);
                                    } else {
                                        res['uid'] = response.user.uid;
                                        proceedToLoggedIn(res);
                                    }
                                })
                        }
                    })
                    .catch((error) => {
                        setLoad(false);
                        try {
                            const appVerifier = window.recaptchaVerifier;
                            if (!appVerifier) {
                                toast.error("reCAPTCHA is not initialized. Please try again.");
                                setLoad(false);
                                return;
                            }
                            appVerifier.render().then((widgetId) => {
                                try {
                                    window.grecaptcha.reset(widgetId);
                                } catch (error) {
                                    console.log(error);
                                }
                            });
                        } catch (error) {
                            console.log(error);
                        }
                        console.log(error.message);
                        toast.error("Entered otp is wrong. Please enter correct otp");
                    });
            }
        }
    };

    //resend otp
    const resendOtp = (e) => {
        e.preventDefault();
        setLoad(true);
        if (inputType === "email") {
            sendOtpToEmail();
        } else {
            const appVerifier = window.recaptchaVerifier;
            if (!appVerifier) {
                toast.error("reCAPTCHA is not initialized. Please try again.");
                setLoad(false);
                return;
            }
            if (authentication_mode === 1) {
                let phone_number = "+" + phoneNumber;
                if (validatePhoneNumber(phone_number)) {
                    api
                        .resend_otp(phoneWithoutCountry)
                        .then((response) => {
                            setIsSend(true);
                            setLoad(false);
                            setConfirmResult(response);
                            toast.success("OTP has been sent");
                        })
                        .catch((error) => {
                            appVerifier.render().then(function (widgetId) {
                                window.grecaptcha.reset(widgetId);
                            });
                            toast.error(error.message);
                            setLoad(false);
                        });
                } else {
                    setLoad(false);
                    toast.error("Please Enter correct Mobile Number with Country Code");
                }
            } else {
                let phone_number = "+" + phoneNumber;
                signInWithPhoneNumber(auth, phone_number, appVerifier)
                    .then((response) => {
                        setIsSend(true);
                        setLoad(false);
                        setConfirmResult(response);
                        toast.success("OTP has been sent");
                    })
                    .catch((error) => {
                        appVerifier.render().then(function (widgetId) {
                            window.grecaptcha.reset(widgetId);
                        });
                        toast.error(error.message);
                        setLoad(false);
                    });
            }
        }
    };


    const handleKeyPress = (event) => {
        //console.log("Key pressed:", event.key);
        if (event.key === "Enter") {
            event.preventDefault();
            onSubmit(event);
        }
    };

    const onChangePhoneNumber = (e) => {
        e.preventDefault();
        setVerificationCode("");
        setConfirmResult(null);
        setIsSend(false);
    };

    const [email, setEmail] = useState('');
    const [inputType, setInputType] = useState('mobile'); // 'mobile' or 'email'
    const toggleInputType = () => {
        setInputType(inputType === 'mobile' ? 'email' : 'mobile');
    };

    return (
        <>
            {!isSend ? (
                <form
                    className="login-form"
                    onSubmit={onSubmit}
                >
                    <label htmlFor="number" className="login-label">
                        {inputType === 'mobile'
                            ? t('please_enter_your_mobile_number')
                            : t('please_enter_your_email_address')}
                    </label>
                    <label htmlFor="number" className="login-label-tooltip" onClick={toggleInputType}>
                        {inputType === 'mobile'
                            ? "Login by Email"
                            : "Login by Phone"}
                    </label>
                    {inputType === 'mobile' ? (
                        <>
                            <PhoneInput
                                value={phoneNumber}
                                country={config.DefaultCountrySelectedInMobile}
                                countryCodeEditable={false}
                                onlyCountries={['us', 'in']}  // Restrict to India and USA
                                autoFocus={true}
                                onChange={(value, data) => {
                                    setPhoneWithoutCountry(
                                        value.slice(data.dialCode.length)
                                    );
                                    setPhoneNumber(value);
                                }}
                                onKeyDown={(event) => handleKeyPress(event)} // Add keypress event handler
                                className="mb-3 form-control mt20 input-custom-border"
                            />
                            <Tooltip title="Login by Email" arrow>
                                <EmailIcon
                                    className="custom-icon"
                                    onClick={toggleInputType} />
                            </Tooltip>
                        </>) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px", // Space between the input and icon
                                }}
                                className="input-group-row"
                            >
                                <TextField
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    fullWidth
                                    required
                                    className="mb-3 form-control mt20 custom-input input-custom-border"
                                    placeholder={"Enter Email"}
                                />

                                <Tooltip title="Login by Phone" arrow>
                                    <PhoneIcon
                                        className="custom-icon1"
                                        onClick={toggleInputType} />
                                </Tooltip>
                            </Box>
                        </>
                    )}

                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="send-button"
                    >
                        {!load ? t("request_otp") : t("please_wait")}
                    </Button>

                </form>
            ) : null}
            {
                isSend ? (
                    <form
                        className="login-form"
                        onSubmit={handleVerifyCode}
                    >
                        <TextField
                            color="error"
                            id="outlined-number"
                            fullWidth
                            label={t("enter_your_otp")}
                            type="text"
                            value={verificationCode}
                            onChange={(e) =>
                                setVerificationCode(e.target.value)
                            }
                            className="form-control"
                        />
                        <div className="text-end mt-2">
                            <div
                                className="resend-otp"
                                onClick={resendOtp}>
                                {t("resend_otp")}
                            </div>
                        </div>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 2,
                                mb: 2
                            }}
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                className="submit-btn"
                            >
                                {!load ? t("submit") : t("please_wait")}
                            </Button>

                            <Button
                                variant="outlined"
                                onClick={onChangePhoneNumber}
                                color="error"
                            >
                                {t("back")}
                            </Button>

                        </Stack>

                    </form>
                ) : null
            }
        </>
    );
};

export default SignIn;
