
import config from "./config";

export const defaultColor = "#932541";
export const secondaryColor = "#ffcc00";
export const defaultStore = "samedaymeats-own-outlet";
//is login
export function isLogin() {
  let user = localStorage.getItem("user");
  if (user) {
    try {
      user = JSON.parse(user);
      if (user.token) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  return false;
}

//logout
export function logout() {
  localStorage.clear();
  localStorage.removeItem("user");
  return true;
}

//get user data
export function getUserData() {
  let user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return false;
}

// Add Script
export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const apiKey = config.YOUR_GOOGLE_MAPS_API_KEY;

export const initMapScript = () => {
  if (window.google) {
    return Promise.resolve();
  }
  const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
  return loadAsyncScript(src);
};

const payStackSrc = "https://js.paystack.co/v1/inline.js";
export const payStackInit = () => {
  return loadAsyncScript(payStackSrc);
};

export const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    country: "",
    zipcode: "",
    area: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }
  const components = place.address_components;
  console.log(components);
  
  components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_1")) { 
      address.state = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }

    if (types.includes("postal_code")) {
      address.zipcode = value;
    }

    // if (types.includes("route")) {
    //   address.area = value;
    // }

    // if (types.includes("administrative_area_level_1")) {
    //   address.area = value;
    // }

  });
  console.log(address);
  return address;
};

export const lat_long = (key, value) => {
  localStorage.setItem(key, value);
};

export const createOrderId = (userId) => {
  const currentTime = new Date();
  const timeInMilliseconds = currentTime.getTime();
  const orderId = `${userId}_${timeInMilliseconds}`;
  localStorage.setItem("user_order_id", orderId);
  return orderId;
};

export const formatData = (str) => {
  if (!str) return '';
  // console.log('Original:', str);
  const formattedData = str.replace(/[\r\n]+/g, ' '); // Global replacement of \r and \n
  // console.log('Formatted Data:', formattedData); // Check the formatted string in console
  return formattedData;
};

export const addressDetails = () => {
  return "SameDayMeats, Dallas";
};

export const addressDetails2 = () => {
  return "4984 Main St, Frisco, TX 75033";
};

export const getStoreName = () => {
  const storeName = localStorage.getItem("storeName");
  return storeName;
};

export const getCartDetailsFromOfflineCartItems = (cartItems) => {
  let cartData = {};
  if (cartItems && cartItems.length > 0) {
    cartData = {
      "message": "Data Retrieved From Cart!",
      "total_quantity": cartItems.reduce((total, item) => total + item['qty'], 0), // Sum of all quantities
      "sub_total": cartItems.reduce((total, item) => total + item['qty'] * item['price'], 0), // Sum of price * qty for each item
      "tax_percentage": 0, // Assuming no tax or modify as needed
      "tax_amount": 0, // No tax
      "overall_amount": cartItems.reduce((total, item) => total + item['qty'] * item['price'], 0), // same as sub_total here
      "total_arr": 0, // Assuming 0 if no specific value provided
      "variant_id": cartItems.map(item => item['variants'][0].id), // Array of variant IDs
      data: cartItems
    };
  }

  return cartData;
};

export const getPartnerId = (cartData) => {
  if (cartData && cartData.data && cartData.data.length > 0) {
    const data = cartData.data[0];
    if (data && data.product_details && data.product_details.length > 0) {
      return cartData?.data[0]?.product_details[0]?.partner_details[0]?.partner_id;
    } else {
      return data.partner_id;
    }
  }
  return "";
};

export const formatDateTime = (dateTimeString) => {
  if (dateTimeString) {
    const date = new Date(dateTimeString);
    // Format options to remove seconds and add AM/PM
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }
  return dateTimeString;
};

export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).format(date);
  }
  return dateString;
};

export const generateOrderDate = () => {
  // Get the current date
  const currentDate = new Date();
  // Add 1 day to the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);
  // Format the date
  const options = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = nextDate.toLocaleDateString("en-US", options);
  // Append time range
  const timeRange = "10AM - 2PM";

  return `${formattedDate} ${timeRange}`;
};

export const loadDefaultData = () => {
  const latitude = config.latitude;
  const longitude = config.longitude;
  const city = config.DefaultCity;
  const city_id = config.DefaultCityId;
  const formatted_address = config.DefaultAddress;

  const locationDetails = {
    lat: latitude,
    lng: longitude,
    city: city,
    city_id: city_id,
    state: '',
    country: '',
    formatted_address: formatted_address
  };

  localStorage.setItem("city", city_id);
  localStorage.setItem("current_city", "dallas");
  localStorage.setItem("longitude", longitude);
  localStorage.setItem("latitude", latitude);
  localStorage.setItem('selectedLocation', JSON.stringify(locationDetails));
  // window.location.reload();
};

export const processCartItems = (cart) => {
  let cartData = [];
  if (cart && cart.length > 0) {
    cart.forEach(item => {
      const processCartItem = {
        "product_variant_id": item.product_variant_id,
        "qty": item.qty,
        "add_on_id": item.addonsId,
        "add_on_qty": item.qty
      };
      cartData.push(processCartItem);
    })
  }
  return cartData;
};

export const toFixedDecimal = (val) => {
  if (val) {
    return Number(val).toFixed(2);
  }
  return val;
};

export const webSettingsData = {
  "error": false,
  "allow_modification": 1,
  "authentication_mode": 0,
  "message": "Settings retrieved successfully",
  "data": {
    "payment_method": {
      "paypal_payment_method": "0",
      "paypal_mode": "sandbox",
      "paypal_business_email": "Paypal Business Email",
      "paypal_client_id": "",
      "paypal_secret_key": "",
      "currency_code": "USD",
      "razorpay_payment_method": "0",
      "razorpay_key_id": "Razorpay key ID",
      "razorpay_secret_key": "Secret Key",
      "refund_webhook_secret_key": "",
      "paystack_payment_method": "0",
      "paystack_key_id": "Paystack key ID",
      "paystack_secret_key": "Secret Key",
      "stripe_payment_method": "1",
      "stripe_payment_mode": "test",
      "stripe_publishable_key": "pk_test_51Q433RKTOIgsaz9GMVKmLj4pUcqPwFeXFi3K863h2dKnae7nr4nR1A8ObYOUBZOcFR1E8woRf92trvYdSyewmQjM00D7YbwIBt",
      "stripe_secret_key": "sk_test_51Q433RKTOIgsaz9Gu1xCNsVcGV1nRy58W9r9de5ED3cWX2PfC9Op7HzhVVAHm2tpcvX1ENK5amp3panxCqK8Qsl800XxIH9zKZ",
      "stripe_webhook_secret_key": "whsec_b07wiPzVN0qI0sWZs5XMEIUIBLs62qvC",
      "stripe_currency_code": "USD",
      "flutterwave_payment_method": "0",
      "flutterwave_public_key": "Flutterwave Public Key",
      "flutterwave_secret_key": "Secret Key",
      "flutterwave_encryption_key": "Flutterwave Encryption key",
      "flutterwave_currency_code": "NGN",
      "paytm_payment_method": "0",
      "paytm_payment_mode": "sandbox",
      "paytm_merchant_key": "Paytm Merchant Key",
      "paytm_merchant_id": "Paytm Merchant ID",
      "paytm_website": "WEBSTAGING",
      "paytm_industry_type_id": "Retail",
      "midtrans_payment_mode": "sandbox",
      "midtrans_payment_method": "0",
      "midtrans_client_key": "",
      "midtrans_merchant_id": "",
      "midtrans_server_key": "",
      "phonepe_payment_mode": "SANDBOX",
      "phonepe_payment_method": "0",
      "phonepe_webhook_url": "https://macroflex.ai/app/v1/api/phonepe_webhook",
      "cod_method": "1"
    },
    "is_cod_allowed": 1,
    "web_settings": [
      {
        "logo": "https://macroflex.ai/",
        "favicon": "https://macroflex.ai/",
        "light_logo": "https://macroflex.ai/"
      }
    ],
    "authentication_mode": 0,
    "tags": [
      "Chicken",
      "Lamb",
      "Sea Food"
    ]
  }
};

export const formatTotal = (val) => val ? parseFloat(val).toFixed(2) : 0;

export const formatTime = (time) => {
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours, 10);

  const isPM = hours >= 12;
  hours = hours % 12 || 12; // Convert to 12-hour format, handling midnight
  const ampm = isPM ? "PM" : "AM";

  return `${hours}:${minutes} ${ampm}`;
};
