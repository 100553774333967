import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
// Singleton Firebase Initialization
let firebaseApp;

const firebaseConfig = {
  apiKey: "AIzaSyD6xRzDL-saFy5eu2seXw-36JDFMvzYxoU",
  authDomain: "sameday-meats-e091b.firebaseapp.com",
  projectId: "sameday-meats-e091b",
  storageBucket: "sameday-meats-e091b.firebasestorage.app",
  messagingSenderId: "609969665623",
  appId: "1:609969665623:web:56b82606e7d4f849c89512",
  measurementId: "G-QJZRB0WDCL"
};

// if (firebase && !firebase.apps.length) {
firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const auth = getAuth(firebaseApp);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const firebaseServices = {
  firebaseApp,
  googleProvider,
  facebookProvider,
  messaging,
};

export default firebaseServices;
