import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import '../../assets/css/delivery-banner.css';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';

function DeliveryLocations() {

    const cities = useSelector((state) => state.Cities)?.cities;
    let locations = [];
    if (cities && cities.length > 0) {
        locations = cities.map(city => city.name);
    }
    // console.log(locations);
    // const locations = [
    //     "Dallas", "Irving", "Richardson", "Copel", "Lewisville", "Carlotton", "Plano",
    //     "Frisco", "prosper", "McKinney", "Celina", "Little Elm", "Flower Mound"
    // ];

    return (
        <Container className="delivery-locations-container" maxWidth={false}>
            <Box className="delivery-location-container">
                <Box className="delivery-banner">
                    <span className="location-icon">
                        <RoomIcon />
                    </span>
                    <span>Available Delivery Locations (TX)</span>
                </Box>

                <div className="chips-container">
                    {locations.map((location, index) => (
                        <Chip
                            key={index}
                            label={location}
                            sx={{
                                marginRight: '8px',
                                backgroundColor: "#ffffff",
                                color: "#b71c1c",
                                border: "1px solid #ffffff",
                            }}
                        />
                        // '& .MuiChip-icon': { color: '#b71c1c' },
                    ))}
                </div>
            </Box>
        </Container>
    );
}

export default DeliveryLocations;
