import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import firebaseconfig, { auth } from "../../../utils/firebase";
import "react-phone-input-2/lib/style.css";
import {
  Typography,
  Modal,
  Box,
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../assets/css/login.css";

import LoginBanner from "./LoginBanner";
import Profile from "./Profile";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import CloseModalButton from "../CloseModalButton";
import firebaseServices from "../../../utils/firebase";
// import InitializeRecaptcha from "../../../utils/InitializeRecaptcha";
import { RecaptchaVerifier } from "firebase/auth";


const Login = ({ label, custom_color }) => {
  const { t } = useTranslation();

  const [LoginOpen, setLoginOpen] = useState(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);
  const [newUserscreen, setNewUserScreen] = useState(false);
  const [isEmailInput, setEmailInputType] = useState("mobile");

  const [profile, setProfile] = useState({
    username: "",
    mobile: "",
    email: "",
    profile: "",
  });

  // const { auth } = firebaseServices; // Use Firebase auth

  //user selector
  const { currentUser } = useAuth();

  //recaptcha verifier
  useEffect(() => {
    // console.log(auth);
    const initializeRecaptcha = () => {
      if (!window.RecaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container",
          {
            size: "invisible", callback: response => { }
          },
          auth
        );
        // console.log(window.reacaptchaVerifier);
      }
    };

    initializeRecaptcha(); // Ensure reCAPTCHA is initialized on component mount

    // return () => {
    //   // Cleanup reCAPTCHA on unmount
    //   if (window.recaptchaVerifier) {
    //     window.recaptchaVerifier.clear();
    //     window.recaptchaVerifier = null;
    //   }
    // };

  }, []);

  // const cart_data = localStorage.getItem("cart");
  // const cart = JSON.parse(cart_data);
  //   console.log(cart);

  return (
    <>
      {currentUser ? (
        <Profile profile={profile} />
      ) : (
        <>
          {!label && <Typography
            variant="subtitle1"
            component="h6"
            sx={custom_color ? custom_color :
              { margin: "auto", cursor: "pointer" }}
            onClick={handleLoginOpen}
          >
            {t("log_in")}
          </Typography>}

          {label &&
            <Button
              variant="outlined"
              type="submit"
              color="error"
              onClick={handleLoginOpen}
            >
              {label}
            </Button>}
        </>
      )}

      <Modal
        open={LoginOpen}
        onClose={handleLoginClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="login-model">
          <LoginBanner newUserscreen={newUserscreen} />
          <CloseModalButton onClick={handleLoginClose} top={"20px"} right={"20px"}
            iconSX={{
              color: "#ffffff",
              cursor: "pointer",
              fontSize: "1.5rem",
            }} />
          {!newUserscreen ?
            (<SignIn
              setNewUserScreen={setNewUserScreen}
              setEmailInputType={setEmailInputType}
              setProfile={setProfile}
              handleLoginClose={handleLoginClose}
              auth={auth}
            />) :
            (<SignUp
              isEmailInput={isEmailInput}
              profile={profile}
              setProfile={setProfile}
              handleLoginClose={handleLoginClose}
              setNewUserScreen={setNewUserScreen}
            />
            )
          }
        </Box>
      </Modal>
      <div id="recaptcha-container"></div>

    </>
  );
};

export default Login;
