import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from "../../../utils/api";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useProfile } from "../../../context/ProfileContext";
import "../../../assets/css/login.css";

const SignUp = ({ profile, setProfile, handleLoginClose, setUserId, isEmailInput, setNewUserScreen }) => {
    const demoMode = process.env.REACT_APP_DEMO_MODE;

    const [phoneWithoutCountry, setPhoneWithoutCountry] = useState(
        demoMode === "true" ? "" : ""
    );
    const [emailId, setEmailId] = useState(
        demoMode === "true" ? "" : ""
    );
    const [isDisabledEmail, setIsDisabledEmail] = useState(false);
    const [isDisabledPhone, setIsDisabledPhone] = useState(false);

    const { t } = useTranslation();
    const { setUserDetails } = useAuth();
    const { setUserInfo } = useProfile();
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");

    const formSubmit = async (e) => {
        e.preventDefault();
        const { username, email, country_code, fcm_id } = profile;
        api
            .register_user(
                username,
                email,
                phoneWithoutCountry,
                country_code,
                "",
                fcm_id,
                "",
                latitude,
                longitude,
                isEmailInput
            )
            .then((response) => {
                if (response.error) {
                    toast.error(response.message);
                } else {
                    setProfile(response.data);
                    const userData = { ...response.data, token: response.token };
                    setUserDetails(userData);
                    // setUserId(response.uid);
                    toast.success("Successfully Registered");
                    localStorage.setItem("user", JSON.stringify(userData));
                    setUserInfo(response.data);
                    handleLoginClose();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleProfileUpdate = (field, value) => {
        setProfile((prev) => ({ ...prev, [field]: value }));
    };

    const backToLogin = () => {
        setNewUserScreen(false);
    };

    useEffect(() => {
        if (isEmailInput === "mobile") {
            setPhoneWithoutCountry(profile.mobile);
            setIsDisabledPhone(true);
        }
        if (isEmailInput === "email") {
            setEmailId(profile.email);
            setIsDisabledEmail(true);
        }
    }, [isEmailInput, profile.email, profile.mobile]);

    return (
        <form className="login-form" onSubmit={formSubmit}>
            <label htmlFor="number" className="signup-label">
                {t("Signup")}
            </label>

            <TextField
                fullWidth
                required
                id="fullName"
                label="Username"
                name="username"
                color="error"
                className="mb20"
                placeholder={"Enter Your Name"}
                defaultValue={profile?.username}
                onChange={(e) => handleProfileUpdate(e.target.name, e.target.value)}
            />

            <TextField
                fullWidth
                required
                id="emailid"
                label="email"
                name="email"
                color="error"
                className="mb20"
                placeholder={"Enter your email"}
                defaultValue={profile?.email}
                disabled={isDisabledEmail}
                onChange={(e) => handleProfileUpdate(e.target.name, e.target.value)}
            />

            <TextField
                fullWidth
                required
                value={phoneWithoutCountry} // Controlled value
                id="mobileid"
                label="mobile"
                color="error"
                className="mb20"
                placeholder={"Enter your Mobile no"}
                defaultValue={profile?.mobile}
                disabled={isDisabledPhone}
                onChange={(e) => setPhoneWithoutCountry(e.target.value)} // Update state directly
            />

            <div style={{
                display: 'flex',
                gap: '5px'
            }}>
                <Button
                    variant="contained"
                    type="submit"
                    className="send-button"
                    fullWidth
                >
                    {t("register")}
                </Button>


                <Button
                    variant="outlined"
                    type="button"
                    className="back-button"
                    fullWidth
                    onClick={backToLogin}
                >
                    {t("Login")}
                </Button>
            </div>
        </form>
    );
};

export default SignUp;
