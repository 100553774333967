import React, { createContext, useContext, useState } from "react";

const CustomModalContext = createContext();

export const ModalCustomContext = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({}); // Store dynamic data like orderId, deliveryDate, etc.

  const openModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData({});
    window.location.reload();
  };

  return (
    <CustomModalContext.Provider value={{ isOpen, modalData, openModal, closeModal }}>
      {children}
    </CustomModalContext.Provider>
  );
};

export const useCustomModal = () => useContext(CustomModalContext);
